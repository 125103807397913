import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_10/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      all: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-10-2"}}) {
        body
      },
      combTitle: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-10-2-1"}}) {
        body
        frontmatter {
          arrow
        }
      }
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
