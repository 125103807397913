import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_10/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      all: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-10-3"}}) {
        body
      },
      toolTips: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-10-3-1"}}) {
        body
        frontmatter {
          arrowleft
          arrowright
          switch
          noise
          bibleQuote
        }
      }
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
